import React from "react";
import {
  Typography,
  Button,
  Box,
  CssBaseline,
  ThemeProvider,
  useTheme,
} from "@mui/material";

const NotFoundPage = () => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          bgcolor: theme.palette.background.default,
        }}
      >
        <Typography variant='h1' color='text.primary' gutterBottom>
          404
        </Typography>
        <Typography variant='h5' color='text.secondary' paragraph>
          Oops! The page you're looking for isn't here.
        </Typography>
        <Button variant='contained' color='primary' href='/' sx={{ mt: 3 }}>
          Go Home
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default NotFoundPage;
