import React, { useRef, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import Slider from "react-slick";
import FooterComponent from "../components/StaticPages/FooterComponent";
import { motion } from "framer-motion";
import ItiniHuntAppBar from "../components/StaticPages/ItiniHuntAppBar";

import heroVideo from "../assets/videos/Itini Hunts.mp4";
import image2 from "../assets/images/groups.png";
import image3 from "../assets/images/itini-hunt-phone.png";
import image4 from "../assets/images/2m-customers.png";
import image5 from "../assets/images/30-restaurants.png";
import DoughnutChart from "../components/DoughnutChart";

const Root = styled("div")(({ theme }) => ({
  flexGrow: 1,
  textAlign: "left",
  marginTop: theme.spacing(5),
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: "#C4FFF1",
  fontWeight: "bold",
  marginBottom: theme.spacing(5),
  fontSize: 30,
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
  },
}));

const ContentTypography = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  width: "80%",
  marginBottom: 15,
  fontSize: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
}));

const Image = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  height: "auto",
  [theme.breakpoints.up("md")]: {
    maxWidth: "60%",
  },
}));

const ItiniHunt = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      setIsMuted(!isMuted);
      videoRef.current.muted = !isMuted;
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  };

  const slideVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <>
      <ItiniHuntAppBar />
      <Root sx={{ bgcolor: "#02434A" }}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "auto",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: { xs: 3, md: 5 },
          }}
        >
          <video
            ref={videoRef}
            width='100%'
            autoPlay
            loop
            muted={isMuted}
            style={{ objectFit: "cover" }}
          >
            <source src={heroVideo} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <Box
            display='flex'
            gap={1}
            position='absolute'
            bottom={20}
            right={20}
            zIndex={1}
          >
            <Button
              onClick={togglePlay}
              sx={{
                bgcolor: "#C4FFF1",
                color: "#000000",
                "&:hover": { bgcolor: "#93FFE6" },
              }}
            >
              {isPlaying ? "Pause" : "Play"}
            </Button>
            <Button
              onClick={toggleMute}
              sx={{
                bgcolor: "#C4FFF1",
                color: "#000000",
                "&:hover": { bgcolor: "#93FFE6" },
              }}
            >
              {isMuted ? "Unmute" : "Mute"}
            </Button>
          </Box>
        </Box>

        <Section bgcolor='#02434A' minHeight='1005' id='pain-point'>
          <motion.div>
            <Typography fontSize={50} fontWeight='bold' color='#C4FFF1'>
              ITINI
            </Typography>
            <Typography
              ml={5}
              fontSize={50}
              fontWeight='bold'
              color='#C4FFF1'
              mb={5}
            >
              HUNTS
            </Typography>
          </motion.div>
          <TitleTypography>
            PAIN POINT (STATISTICAL PROBLEM IN THE INDUSTRY)
          </TitleTypography>
          <motion.div>
            <ContentTypography mb={5}>
              - 80% of Customers Are Likely to Choose Restaurants with Unique
              Experiences
            </ContentTypography>
            <ContentTypography mb={5}>
              - Research shows that 72% of customers won’t return if they
              experience long wait times or poor service
            </ContentTypography>
            <ContentTypography mb={5}>
              - Nearly 92% of customers read online reviews before choosing a
              restaurant, and 33% won’t go to a place with fewer than four stars
            </ContentTypography>
          </motion.div>
          <Slider {...settings}>
            <motion.div
              variants={slideVariants}
              initial='hidden'
              animate='visible'
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                padding={2}
                sx={{
                  gap: { xs: 2, sm: 5, md: 15 },
                }}
              >
                <motion.div
                  whileHover={{ scale: 1.1, rotate: 5 }}
                  transition={{ duration: 0.3 }}
                >
                  <DoughnutChart
                    title='80%'
                    dataPoints={[
                      { label: "Likely choose", y: 80 },
                      { label: "Others", y: 20 },
                    ]}
                  />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.1, rotate: -5 }}
                  transition={{ duration: 0.3 }}
                >
                  <Image src={image2} alt='Groups' />
                </motion.div>
              </Box>
            </motion.div>
            <motion.div
              variants={slideVariants}
              initial='hidden'
              animate='visible'
            >
              <Box
                display='flex'
                justifyContent='center'
                sx={{
                  gap: { xs: 2, sm: 5 },
                }}
                alignItems='center'
                padding={2}
              >
                <motion.div
                  whileHover={{ scale: 1.1, rotate: 5 }}
                  transition={{ duration: 0.3 }}
                >
                  <DoughnutChart
                    title='72%'
                    dataPoints={[
                      { label: "Won't return", y: 72 },
                      { label: "Others", y: 28 },
                    ]}
                  />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.1, rotate: -5 }}
                  transition={{ duration: 0.3 }}
                >
                  <Image src={image2} alt='Groups' />
                </motion.div>
              </Box>
            </motion.div>
            <motion.div
              variants={slideVariants}
              initial='hidden'
              animate='visible'
            >
              <Box
                display='flex'
                justifyContent='center'
                sx={{
                  gap: { xs: 2, sm: 5 },
                }}
                alignItems='center'
                padding={2}
              >
                <motion.div
                  whileHover={{ scale: 1.1, rotate: 5 }}
                  transition={{ duration: 0.3 }}
                >
                  <DoughnutChart
                    title='92%'
                    dataPoints={[
                      { label: "Read online reviews", y: 92 },
                      { label: "Read online reviews", y: 33 },
                      { label: "Others", y: 8 },
                    ]}
                  />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.1, rotate: -5 }}
                  transition={{ duration: 0.3 }}
                >
                  <Image src={image2} alt='Groups' />
                </motion.div>
              </Box>
            </motion.div>
          </Slider>
          <motion.div>
            <Typography
              fontWeight='bold'
              fontSize={20}
              mt={5}
              mb={5}
              color='#C4FFF1'
            >
              Today's diners expect more than just good food—they want an
              experience. With long wait times and stiff competition, how do you
              stand out?
            </Typography>
          </motion.div>
        </Section>
        <motion.div variants={slideVariants} initial='hidden' animate='visible'>
          <Section bgcolor='#01545C' id='solution'>
            <Box
              display='flex'
              flexDirection={{ xs: "column", md: "row" }}
              alignItems='center'
            >
              <Box>
                <motion.div>
                  <TitleTypography>Our Solution</TitleTypography>
                </motion.div>
                <ContentTypography mb={5}>
                  Elevate Customer Engagement with Digital Scavenger Hunts
                </ContentTypography>
                <motion.div>
                  <Typography
                    fontWeight='bold'
                    fontSize={20}
                    mb={5}
                    color='#C4FFF1'
                  >
                    We bring your restaurant's story to life through customized
                    scavenger hunts, turning idle wait times into interactive
                    moments that enhance brand loyalty.
                  </Typography>
                </motion.div>
                <motion.div>
                  <Typography
                    fontWeight='bold'
                    fontSize={20}
                    mb={5}
                    color='#C4FFF1'
                  >
                    Use this as a way to upsell your menu, your aesthetics, or
                    your company culture. Let us help you directly deliver this
                    message to your customer.
                  </Typography>
                </motion.div>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                padding={2}
              >
                <motion.div whileHover={{ scale: 1.1 }}>
                  <Image
                    sx={{
                      width: { xs: "60%" },
                    }}
                    src={image3}
                    alt='phone image'
                  />
                </motion.div>
              </Box>
            </Box>
          </Section>
        </motion.div>

        <motion.div
          whileInView={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.8 }}
          variants={slideVariants}
          animate='visible'
        >
          <Section bgcolor='#02434A' id='benefits'>
            <Box
              display='flex'
              flexDirection={{ xs: "column", md: "row" }}
              alignItems='center'
            >
              <Box>
                <motion.div>
                  <TitleTypography>KEY BENEFITS</TitleTypography>
                </motion.div>
                <Box mb={10}>
                  <ContentTypography mb={5}>
                    Innovative Organic Marketing:
                  </ContentTypography>
                  <motion.div>
                    <Typography fontWeight='bold' fontSize={20} color='#C4FFF1'>
                      Create organic traffic encourages customers to engage and
                      share feedback in a more meaningful, detailed way,
                      providing you with richer data to enhance your service.
                    </Typography>
                  </motion.div>
                </Box>
                <Box mb={10}>
                  <ContentTypography mb={5}>
                    Engage Your Customers:
                  </ContentTypography>
                  <motion.div>
                    <Typography fontWeight='bold' fontSize={20} color='#C4FFF1'>
                      Storytelling and gamification that highlight your entire
                      restaurant experience.
                    </Typography>
                  </motion.div>
                </Box>
                <Box mb={10}>
                  <ContentTypography mb={5}>
                    Real-Time Feedback:
                  </ContentTypography>
                  <motion.div>
                    <Typography fontWeight='bold' fontSize={20} color='#C4FFF1'>
                      Gather valuable data and reviews to improve customer
                      satisfaction.
                    </Typography>
                  </motion.div>
                </Box>
                <Box mb={10}>
                  <ContentTypography mb={5}>Fill Wait Times:</ContentTypography>
                  <motion.div>
                    <Typography fontWeight='bold' fontSize={20} color='#C4FFF1'>
                      Offer engaging activities while customers wait, enhancing
                      their overall experience.
                    </Typography>
                  </motion.div>
                </Box>
                <Box mb={10}>
                  <ContentTypography mb={5}>
                    Build Brand Community:
                  </ContentTypography>
                  <motion.div>
                    <Typography fontWeight='bold' fontSize={20} color='#C4FFF1'>
                      Increase retention and attract new customers with our
                      co-branding algorithm and local restaurant community.
                    </Typography>
                  </motion.div>
                </Box>
              </Box>
            </Box>
          </Section>
        </motion.div>

        <motion.div
          whileInView={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.8 }}
          variants={slideVariants}
          animate='visible'
        >
          <Section bgcolor='#01545C' id='stats'>
            <Box flex={1}>
              <motion.div>
                <TitleTypography>SUCCESS STATS</TitleTypography>
                <ContentTypography>PROVEN SUCCESS</ContentTypography>
              </motion.div>
              <motion.div>
                <Typography
                  fontWeight='bold'
                  mb={5}
                  mt={5}
                  fontSize={20}
                  color='#FFFFFF'
                >
                  Our platform connects you with over 2 million customers and is
                  already trusted by 30 restaurants. Together, we’re reshaping
                  dining experiences.
                </Typography>
              </motion.div>
              <motion.div>
                <Typography
                  fontWeight='bold'
                  mb={5}
                  fontSize={20}
                  color='#FFFFFF'
                >
                  Research shows that 70% of customers won’t return if they
                  experience long wait times or poor service.
                </Typography>
              </motion.div>
              <motion.div>
                <Typography
                  fontWeight='bold'
                  mb={5}
                  fontSize={20}
                  color='#FFFFFF'
                >
                  Nearly 92% of customers read online reviews before choosing a
                  restaurant, and 33% won’t go to a place with fewer than four
                  stars.
                </Typography>
              </motion.div>
            </Box>
            <Box
              flex={1}
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{
                gap: { xs: 1, sm: 5, md: 25 },
              }}
            >
              <Image src={image4} alt='2m+ customers' />
              <Image src={image5} alt='30+ restaurants' />
            </Box>
          </Section>
        </motion.div>

        <motion.div
          whileInView={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.8 }}
          variants={slideVariants}
          animate='visible'
        >
          <Section
            display='flex'
            flexDirection='column'
            alignItems='center'
            bgcolor='#02434A'
            textAlign='center'
          >
            <motion.div>
              <Typography
                fontWeight='bold'
                color='#C4FFF1'
                fontSize={30}
                mb={5}
              >
                READY TO ELEVATE YOUR BRAND?
              </Typography>
            </motion.div>
            <ContentTypography>
              Contact us today for a demo and discover how gamified experiences
              can set your restaurant apart.
            </ContentTypography>
            <Button
              variant='contained'
              sx={{
                bgcolor: "#C4FFF1",
                color: "#000000",
                fontWeight: "bold",
                borderRadius: 5,
                "&:hover": {
                  backgroundColor: "#93FFE6",
                },
              }}
              href='https://tkwdef6eult.typeform.com/to/eoJBvAUl'
              target='_blank'
              rel='noopener noreferrer'
            >
              Get Started
            </Button>
          </Section>
        </motion.div>
      </Root>
      <FooterComponent backgroundColor='#01545C' color='#FFFFFF' />
    </>
  );
};

export default ItiniHunt;
