import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const centerTextPlugin = {
  id: "centerText",
  beforeDraw: (chart) => {
    const width = chart.width,
      height = chart.height,
      ctx = chart.ctx;

    ctx.restore();
    const fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "#037682";

    const text = chart.config._config.data.datasets[0].label,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};

const DoughnutChart = ({ dataPoints, title }) => {
  const data = {
    labels: dataPoints.map((dp) => dp.label),
    datasets: [
      {
        label: title,
        data: dataPoints.map((dp) => dp.y),
        backgroundColor: ["#41b8d5", "#037682", "#02434A"],
        borderColor: "none",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      centerTextPlugin,
    },
    cutout: "70%",
  };

  return (
    <div>
      <Doughnut data={data} options={options} plugins={[centerTextPlugin]} />
    </div>
  );
};

export default DoughnutChart;
