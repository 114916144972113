import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Link,
  List,
  ListItem,
  Button,
  Typography,
  Box,
  ListItemButton,
  useScrollTrigger,
} from "@mui/material";
import { styled } from "@mui/system";
import { ReactComponent as MenuIcon } from "../../assets/images/menu-icon.svg";
import logo from "../../assets/images/logo-brown.png";

const AppBarContainer = styled(AppBar)(
  ({ theme, hasTitle, backgroundColor }) => ({
    backgroundColor: backgroundColor
      ? backgroundColor
      : hasTitle
      ? "#E0FFF8"
      : "white",
    borderBottomRightRadius: hasTitle ? "80px" : "none",
    borderBottomLeftRadius: hasTitle ? "80px" : "none",
    paddingBottom: hasTitle ? "40px" : "40px",
    [theme.breakpoints.down("sm")]: {
      borderBottomRightRadius: hasTitle ? "40px" : "none",
      borderBottomLeftRadius: hasTitle ? "40px" : "none",
      paddingBottom: hasTitle ? "20px" : "20px",
    },
  })
);

const ToolbarContent = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  [theme.breakpoints.down("sm")]: {
    left: "45%",
    transform: "translateX(-50%)",
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  color: "#02434A",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0.5),
  },
}));

const TitleSection = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "5% 2% 0",
  [theme.breakpoints.down("sm")]: {
    margin: "10% 2% 0",
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: "2.5rem",
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const DrawerFooter = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  width: "95%",
  margin: "0 5px",
  textAlign: "center",
}));

const DrawerLogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 50,
  margin: theme.spacing(2, 0),
}));

// const scrollAnimation = keyframes`
//   0% { transform: translateX(100%); }
//   100% { transform: translateX(-100%); }
// `;

const AnimatedText = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  // animation: `${scrollAnimation} 10s linear infinite`,
}));

const AnnouncementBar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: "#02434A",
  padding: theme.spacing(1),
  textAlign: "center",
  mb: 1,
}));

const AppBarComponent = ({
  title,
  backgroundColor,
  menuBtnColor,
  itiniHunt,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [displayTitle, setDisplayTitle] = useState(!!title);
  const navigate = useNavigate();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    if (!title) return;

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setDisplayTitle(false);
      } else {
        setDisplayTitle(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [title]);

  return (
    <div>
      <AppBarContainer
        backgroundColor={backgroundColor}
        position='fixed'
        elevation={trigger ? 4 : 0}
        hasTitle={!!title && displayTitle}
      >
        <AnnouncementBar
        >
          <AnimatedText
            fontWeight='bold'
            noWrap
            sx={{
              fontSize: { xs: "1rem", md: "1.5rem" },
            }}
          >
            Welcome to Itini! Check out our latest travel deals and events!
            🔥🔥🔥
          </AnimatedText>
        </AnnouncementBar>
        <Box sx={{ backgroundColor: "white", height: 10 }}></Box>
        <ToolbarContent>
          <MenuButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={toggleDrawer(true)}
          >
            <MenuIcon
              style={{ color: menuBtnColor ? menuBtnColor : "inherit" }}
            />
          </MenuButton>
          <Link href='/'>
            <LogoContainer>
              <img src={logo} alt='Itini Logo' style={{ height: 50 }} />
            </LogoContainer>
          </Link>
        </ToolbarContent>
        {displayTitle && (
          <TitleSection>
            <TitleTypography variant='h3'>{title}</TitleTypography>
          </TitleSection>
        )}
      </AppBarContainer>
      <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role='presentation'
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Link href='/'>
            <DrawerLogoContainer>
              <img src={logo} alt='Itini Logo' style={{ height: 50 }} />
            </DrawerLogoContainer>
          </Link>
          <List>
            <ListItem>
              <ListItemButton onClick={() => navigate("/")}>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                  Home
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => navigate("/about-us")}>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                  About Us
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => navigate("/about-us#press")}>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                  Press
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => navigate("/partnership")}>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                  Partner with us
                </Typography>
              </ListItemButton>
            </ListItem>
            {/* <ListItem>
              <ListItemButton>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                  Connect with us
                </Typography>
              </ListItemButton>
            </ListItem> */}
            <ListItem>
              <ListItemButton onClick={() => navigate("/itini-hunt")}>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                  Itini Hunt
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => navigate("/contact-us")}>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                  Help
                </Typography>
              </ListItemButton>
            </ListItem>
          </List>
          <DrawerFooter>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Link href='https://itini.ai/sign-in'>
                <Button variant='outlined' fullWidth>
                  Sign in
                </Button>
              </Link>

              <Link href='https://itini.ai/sign-up'>
                <Button variant='contained' color='primary' fullWidth>
                  Get started
                </Button>
              </Link>
            </Box>
            <Box mt={2}>
              <Typography
                display='flex'
                variant='body2'
                gap={2}
                justifyContent='space-between'
              >
                <Link href='/privacy-policy' color='inherit' underline='none'>
                  Privacy Policy
                </Link>
                ●
                <Link href='/terms-of-service' color='inherit' underline='none'>
                  Terms of Service
                </Link>
              </Typography>
              <Typography variant='body2'>&copy; 2024 Itini Inc.</Typography>
            </Box>
          </DrawerFooter>
        </Box>
      </Drawer>
    </div>
  );
};

export default AppBarComponent;
